<template>
    <main class="flex flex-col px-8 py-24 md:flex-row md:px-32">
        <div class="w-32">
            <h2 class="text-black standard-subheading">
                TERMS OF USE
            </h2>
        </div>

        <div class="w-full pt-8 pr-8 md:pl-32 md:pt-0">
            <p>
                PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR USING THIS WEBSITE, YOU AGREE TO BE BOUND BY THE TERMS DESCRIBED HEREIN AND ALL TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THIS WEBSITE.
            </p>

            <p class="pt-4">
                These Terms of Use apply to your access to, and use of, all or part of any Canadian website or mobile application of Gordon L Diewert Foundation or its subsidiaries and cause marketing websites (collectively, “<span class="font-bold">FOUNDATION</span>”), including https://diewert.org/ and any other Canadian site, mobile application, or online service where these Terms of Use are posted (collectively, the “<span class="font-bold">Sites</span>”). These Terms of Use do not alter in any way the terms or conditions of any other agreement you may have with DIEWERT for products, services, or otherwise. If you are using the Sites on behalf of any entity, you represent and warrant that you are authorized to accept these Terms of Use on such entity’s behalf, and that such entity agrees to indemnify you and DIEWERT for violations of these Terms of Use. <span class="font-bold">This agreement contains disclaimers and other provisions that limit our liability to you. These Terms of Use do not apply to websites and services (including DIEWERT websites, applications and services) that display or link to different Terms of Use.</span>
            </p>

            <p class="pt-4">
                In the event there is any conflict or inconsistency between these Terms of Use and any other terms of use that appear on the Sites, these Terms of Use will govern. However, if you navigate away from the Sites to a third party site, you may be subject to alternative terms and conditions of use, as may be specified on such site. In such event, the terms and conditions of use applicable to that site will govern your use of that site.
            </p>

            <p class="pt-4">
                <span class="font-bold">While we make reasonable efforts to provide accurate and timely information about Gordon L. Diewert Foundation on the Sites, you should not assume that the information is always up to date or that the Sites contain all the relevant information available about DIEWERT. In particular, if you are making an investment decision regarding DIEWERT, please consult a number of different sources.</span>
            </p>

            <p class="pt-4">
                DIEWERT reserves the right to change or modify these Terms of Use or any policy or guideline or other element of the Sites, at any time and in its sole discretion. Any changes or modifications will be effective immediately upon posting the revisions to the Sites, and you waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the Sites will confirm your acceptance of such changes or modifications; therefore, you should frequently review these Terms of Use and applicable policies to understand the terms and conditions that apply to your use of our Sites. If you do not agree to the amended terms, you must stop using the Sites.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Eligibility, Registration and Account
            </h3>

            <p>
                The Sites are not targeted towards, nor intended for use by, anyone under the age of 13. If you are between the ages of 13 and the age of majority in the jurisdiction in which you reside, you may only use the Sites under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use.
            </p>

            <p class="pt-4">
                In order to participate in certain areas of our Sites, you will need to register. In doing so, you agree to (a) create only one registration; (b) provide accurate, truthful, current and complete information when registering; (c) maintain and promptly update your registration information; (d) promptly notify DIEWERT if you discover or otherwise suspect any security breaches relating to the Sites; and (e) take responsibility for all activities that occur under your account and accept all risks of unauthorized access.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Privacy
            </h3>

            <p>
                Please read our Privacy Policy carefully to understand how DIEWERT collects, uses and discloses personally identifiable information from its users, including you. Our Privacy Policy is located at: <a href="http://www.diewert.org/privacy-policy/">http://www.diewert.org/privacy-policy/</a>.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                DIEWERT Mobile CARDS and MARKETPLACES
            </h3>

            <p>
                DIEWERT  may allow you to purchase DIEWERT Mobile Cards through the Sites or third party licensee sites. Please write to info@diewert.org for more information about the terms, conditions and policies that apply to your purchase and use of DIEWERT Mobile Cards.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Site Disclaimer
            </h3>

            <p>
                <span class="font-bold">The materials and information on the Sites may include technical inaccuracies or typographical errors. The materials, information, and services on the Sites are provided “as is” without any conditions, warranties or other terms of any kind. Accordingly, to the maximum extent permitted by applicable law, DIEWERT disclaims all other warranties, express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement as to the Sites and the information, content, and materials contained therein.</span>
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Copyright and Limited License
            </h3>

            <p>
                Unless otherwise indicated, the Sites and all content and other materials therein, including, without limitation, the DIEWERT logo and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof (collectively, “<span class="font-bold">Site Materials</span>”) are the proprietary property of DIEWERT or its licensors or users.
            </p>

            <p class="pt-4">
                You are granted a limited license, with no right of sub-license, to access and use the Sites and Site Materials for personal, informational, and shopping purposes only. Such license is subject to the Terms of Use and does not include: (a) any resale or commercial use of the Sites or Site Materials; (b) the collection and use of any product listings, pictures or descriptions; (c) the distribution, public performance or public display of any Site Materials; (d) modifying or otherwise making any derivative uses of the Sites and the Site Materials, or any portion thereof; (e) use of any data mining, robots or similar data gathering or extraction methods; (f) downloading (other than the page caching) of any portion of the Sites, the Site Materials or any information contained therein, except as expressly permitted on the Sites; or (g) any use of the Sites or the Site Materials other than for its intended purpose. Any use of the Sites or Site Materials other than as specifically authorized herein, without the prior written permission of DIEWERT, is strictly prohibited and will terminate the license granted herein. Such unauthorized use may also violate applicable laws, including, without limitation, copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in these Terms of Use shall be construed as conferring to you in any manner, whether by implication, estoppel or otherwise, any title or ownership of, or exclusive use-rights to, any intellectual property or other right and any goodwill associated therewith.
            </p>

            <p class="pt-4">
                All rights not expressly granted are reserved.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                License to User Content
            </h3>

            <p>
                You represent and warrant that any material you, submit, post, upload, or otherwise transmit to DIEWERT through the Sites (“<span class="font-bold">User Content</span>”) is not subject to any confidentiality obligations and that you own and control all of the rights to the User Content or otherwise have the right to grant the rights to DIEWERT that you grant herein. DIEWERT claims no ownership or control over any User Content, except as otherwise provided herein, on the Sites, or in a separate agreement. However, by submitting or posting User Content on the Sites, you grant DIEWERT and its designees a worldwide, perpetual, irrevocable, non-exclusive, fully-paid up and royalty free license to use, sell, reproduce, prepare derivative works, combine with other works, alter, translate, distribute copies, display, perform, publish, license, or sub-license the User Content and your name and likeness in connection with such use of your User Content. By posting User Content, you hereby release DIEWERT and its agents and employees from any claims that such use, as authorized above, violates any of your rights and you understand that you will not be entitled to any compensation for any use of your User Content.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Submission of Ideas
            </h3>

            <p>
                Separate and apart from the User Content you provide, you can submit questions, comments, feedback, suggestions, ideas, plans, notes, drawings, original or creative materials or other information about DIEWERT, our Sites, and our products (collectively, “<span class="font-bold">Ideas</span>”). Ideas, whether posted to the Sites or provided to DIEWERT by email or otherwise are entirely voluntary, non-confidential, gratuitous, and non-committal. DIEWERT shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of Ideas for any purpose, commercial or otherwise, without acknowledgment or compensation to you; and you agree to execute any documents required by DIEWERT to confirm such rights. Do not send us Ideas if you expect to be paid or want to continue to own or claim rights in them; your Ideas might be great, but we may have already had the same or similar ideas and we do not want disputes.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Links
            </h3>

            <p>
                You are granted a limited, non-exclusive right to create text hyperlinks to the Sites for non-commercial purposes, provided such links do not portray DIEWERT in a false, misleading, derogatory or otherwise defamatory manner and provided further that the linking site does not contain any obscene, pornographic, sexually explicit, or illegal material or any material that is offensive, harassing, or otherwise objectionable. This limited right may be revoked at any time. In addition, you may not use DIEWERT’s logo or other proprietary graphics to link to our Sites without our express written permission. Further, you may not use, frame, or utilize framing techniques to enclose any DIEWERT trademark, logo or other proprietary information, including the images found at the Sites, the content of any text or the layout or design of any page or form contained on a page on the Sites without our express written consent. Except as noted above, you are not conveyed any right or license by implication, estoppel, or otherwise in or under any patent, trademark, copyright or proprietary right of DIEWERT or any third party.
            </p>

            <p class="pt-4">
                DIEWERT makes no claim or representation regarding, and accepts no responsibility for, the quality, content, nature or reliability of embedded content, third-party websites accessible via hyperlink, or websites linking to the Sites. Such sites are not under the control of DIEWERT and DIEWERT is not responsible for any embedded content or the content of any linked site or any link contained in a linked site, or any review, changes or updates to such sites. DIEWERT and its users may provide these links as a convenience to you, but the inclusion of any link does not imply affiliation, endorsement, or adoption by DIEWERT of any site or any information contained therein. When you visit other sites via links or embedded content, you should understand that our terms and policies no longer govern and that the terms and policies of those third party sites will now apply. You should review the applicable terms and policies, including privacy and data gathering practices, of any site to which you navigate from our Sites.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Third Party Content
            </h3>

            <p>
                DIEWERT may provide third party content on the Sites and links to web pages and content of third parties (collectively, “<span class="font-bold">Third Party Content</span>”) as a service to those interested in this information. We do not control, endorse, or adopt any Third Party Content and make no guarantee as to its accuracy or completeness. You acknowledge and agree that DIEWERT: (i) is not responsible or liable in any manner for any Third Party Content; and (ii) undertakes no responsibility to update or review such Third Party Content. You agree to use such Third Party Content contained therein at your own risk.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Advertisements and Promotions; Third-Party Products and Services
            </h3>

            <p>
                DIEWERT may display advertisements and promotions from third parties on the Sites or may otherwise provide information about or links to third-party products or services. Your business dealings or correspondence with, or participation in promotions of, such third parties, and any terms conditions, warranties, or representations associated with such dealings or promotions, are solely between you and such third including. DIEWERT is not responsible or liable for any loss or damage of any sort incurred as the result of such dealings or promotions or as the result of the presence of such third party advertisers or third party information on the Sites.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Limitation on Liability
            </h3>

            <p>
                <span class="font-bold">To the maximum extent permitted by applicable laws, DIEWERT and its officers, directors, employees, shareholders, or agents shall not be liable for any direct, indirect, punitive, or consequential damages, or any other damages of any kind, including but not limited to loss of income, profits, goodwill, data, contracts, use of money, or loss or damage arising from or connected in any way to business interruption, whether in tort (including without limitation negligence), contract, or otherwise, arising out of or in connection with the use of or inability to use the Sites, the content or the materials contained in or accessed through the Sites, including without limitation any damages caused by or resulting from reliance by a user on any information obtained from DIEWERT, or any damages that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from acts of God, communications failure, theft, destruction or unauthorized access to DIEWERT records, programs, or services. In no event shall the aggregate liability of DIEWERT, whether in contract, warranty, tort (including negligence, whether active, passive, or imputed), product liability, strict liability, or other theory, arising out of or relating to the use of or inability to use the Sites exceed any compensation you pay, if any, to DIEWERT for access to or use of the Sites. Some jurisdictions do not allow the limitation of liability in contracts with consumers, so some or all of these limitations of liability may not apply to you.</span>
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Indemnification
            </h3>

            <p>
                You agree to defend, indemnify and hold harmless DIEWERT, its independent contractors, service providers, and consultants, and their respective directors, employees, and agents, from and against any claims, damages, costs, liabilities, or expenses (including, but not limited to, reasonable legal fees) arising out of or related to: (a) your use of the Sites; (b) any User Content or Ideas you provide; (c) your violation of these Terms of Use; (d) your violation of any rights of another; or (e) your conduct in connection with the Sites.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Modifications to the Sites
            </h3>

            <p>
                DIEWERT reserves the right to modify or discontinue, temporarily or permanently, the Sites or any features or portions thereof without prior notice. You agree that DIEWERT will not be liable for any modification, suspension or discontinuance of the Sites or any part thereof.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Jurisdiction and Compliance with Laws
            </h3>

            <p>
                Access to and use of the Sites and these Terms of Use are governed by the laws of Canada and the Province of British Columbia, without resort to conflict of law provisions. Any legal action or proceeding relating to your access to, or use of, the Sites or these Terms of Use shall be instituted only in a court located in Vancouver, British Columbia. You and DIEWERT agree to submit to the jurisdiction of, and agree that venue is proper in, these courts in any such legal action or proceeding.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Termination
            </h3>

            <p>
                Notwithstanding any of these Terms of Use, DIEWERT reserves the right, without notice and in its sole discretion, to terminate your license to use the Sites and to block or prevent your future access to, and use of, the Sites.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Severability
            </h3>

            <p>
                If any provision of these Terms of Use shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions.
            </p>

            <h3 class="pt-4 pb-2 standard-pheading">
                Contact Us
            </h3>

            <p>
                Please feel free to contact us with any comments, questions or suggestions you might have regarding the information described in the Sites.
            </p>

            <p class="pt-4">
                You may contact us at: info@diewert.org
            </p>



        </div>
    </main>
</template>

<script>
export default {
    name: 'TermsOfUse',

    props: {},

    data() {
        return {};
    },

    computed: {},

    methods: {},

};
</script>
